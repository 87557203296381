import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

export const Navbar = () => {
    const [isNavHidden, setIsNavHidden] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { pathname } = useLocation();

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        setIsNavHidden(true); // Close the navigation panel
    }, [pathname]);

    return (
        <nav id='navbar' className={`${isMobile ? "mobile" : ""}`}>
            {isMobile && (
                <div id='mobileHeader'>
                    <h1> SAM CONRAN </h1>
                    <span className={`iconSpan ${isNavHidden ? "" : "active"}`} onClick={() => setIsNavHidden(!isNavHidden)}>
                        <GiHamburgerMenu className='icon' size={35}></GiHamburgerMenu>
                    </span>
                </div>
            )}
            <ul className={`${isMobile && isNavHidden ? "hidden" : ""}`}>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? " active" : "")} to='/' end>
                        Projects
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? " active" : "")} to='/about'>
                        About
                    </NavLink>
                </li>
                {!isMobile && (
                    <li className='heading'>
                        <h1> SAM CONRAN </h1>
                    </li>
                )}
                <li>
                    <NavLink className={({ isActive }) => (isActive ? " active" : "")} to='/contact'>
                        Contact
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? " active" : "")} to='/experiments'>
                        Experiments
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};
