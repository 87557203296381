import React from "react";
import { Navbar } from "./components/Navbar";
import { Projects } from "./Pages/Projects";
import { About } from "./Pages/About";
import { Contact } from "./Pages/Contact";
import { Experiments } from "./Pages/Experiments";
import { Admin } from "./Pages/Admin";
import { Login } from "./Pages/Login";
import { NotFound } from "./Pages/NotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { AddProject } from "./components/projects/AddProject";

const App = () => (
    <div className='App'>
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path='/' element={<Projects />}></Route>
                <Route path='/about' element={<About />}></Route>
                <Route path='/contact' element={<Contact />}></Route>
                <Route path='/experiments' element={<Experiments />}></Route>
                <Route path='/admin' element={<Admin />}></Route>
                <Route path='/admin/project' element={<AddProject />}></Route>
                <Route path='/login' element={<Login />}></Route>
            </Routes>
        </BrowserRouter>
    </div>
);

export default App;
