import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const FirebaseApp = initializeApp({
    apiKey: "AIzaSyA3CxvTDzg58bJ8gxzY9zmHrII6QFJ3IUI",
    authDomain: "conrantest.firebaseapp.com",
    projectId: "conrantest",
    storageBucket: "conrantest.appspot.com",
    messagingSenderId: "136645284006",
    appId: "1:136645284006:web:dee721862cb1596ce1e467"
});

const auth = getAuth(FirebaseApp);
const db = getFirestore(FirebaseApp);

export { FirebaseApp, db, auth };
