import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/authHelper";
import { useNavigate } from "react-router-dom";

export const AddProject = () => {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            navigate("/");
        }
    }, [user]);
    return <div>AddProject</div>;
};
