import { auth } from "./firebaseHelper";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const EmailLogin = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err.code);
    }
};

const ResetPassword = email => {
    sendPasswordResetEmail(auth, email);
};

export { EmailLogin, ResetPassword, auth };
