import { Page } from "../components/Page";

export const NotFound = props => (
    <Page>
        {" "}
        <div id='notFoundView'>
            <h1 className='centre'>404</h1>
            <p className='centre'>Content not found!</p>
        </div>
    </Page>
);
