import { Page } from "../components/Page";

export const About = props => (
    <Page>
        <div id='aboutView'>
            <h1 className='centre'>About page!</h1>
            <p className='centre'>I'll have to actually write some stuff about me here...</p>
        </div>
    </Page>
);
