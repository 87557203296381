import { Page } from "../components/Page";

export const Experiments = props => (
    <Page>
        <div id='aboutView'>
            <h1 className='centre'>Experiments...</h1>
            <p className='centre'>I'll have to have a play around here...</p>
        </div>
    </Page>
);
