import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/authHelper";
import { Page } from "../components/Page";

export const Admin = () => {
    const [user, loading] = useAuthState(auth);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user) {
            setIsAdmin(true);
        }
    }, [user]);

    return <Page>{isAdmin ? <div id='testView'>Ye you is admin!!</div> : <div id='testView'>You are not admin!</div>}</Page>;
};
