import { useState, useEffect } from "react";
import { EmailLogin, auth } from "../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Page } from "../components/Page";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    const handleSubmit = async e => {
        e.preventDefault();
        console.log(`User credentials are ${email} | ${password}`);
        const user = await EmailLogin(email, password);
        console.log(user);
    };

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            console.log("Incorrect Credentials...");
            return;
        } else {
            navigate("/");
            return;
        }
    }, [user]);

    return (
        <Page>
            {" "}
            <div id='LoginView'>
                <p className='centre'>Welcome, me. Please login.</p>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input type='text' name='email' id='emailInput' value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='password'>Password</label>
                        <input type='password' name='password' id='passwordInput' value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                    <button type='submit'>Submit</button>
                </form>
            </div>
        </Page>
    );
};
