import { ProjectList } from "../components/projects/ProjectList";
import { Page } from "../components/Page";

export const Projects = () => (
    <Page>
        <div id='homeView'>
            <ProjectList />
        </div>
    </Page>
);
