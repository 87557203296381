import React from "react";
import styled from "styled-components";

const PageTemplate = styled.div`
    top: 8vh;
    width: 100vw;
    position: absolute;
    padding 2vh 4vw;
`;

export const Page = ({ children }) => {
    return <PageTemplate>{children}</PageTemplate>;
};
