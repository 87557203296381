import { Page } from "../components/Page";

export const Contact = props => (
    <Page>
        <div id='aboutView'>
            <h1 className='centre'>Contact page!</h1>
            <p className='centre'>I'll have to actually write some stuff about contacting me here...</p>
        </div>
    </Page>
);
