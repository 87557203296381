import React, { useEffect, useState } from "react";
import { Project } from "./Project";

export const ProjectList = () => {
    const [projects, setProjects] = useState([]);

    useEffect(_ => {
        (async () => {
            const projects = await (await fetch("/api/projects")).json();
            setProjects(projects);
        })();
    }, []);

    return (
        <div id='projectList'>
            {projects.map((project, index) => (
                <Project project={project} key={index} />
            ))}
        </div>
    );
};
